<template>
  <div>
    <Header></Header>

    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <Sidebar></Sidebar>

          <v-col cols="10">
            <!-- <v-sheet min-height="70vh" rounded="lg"> -->
            <!--  -->

            <Word v-bind:word="word"></Word>

            <!-- </v-sheet> -->
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import axios from "axios";
import Word from "@/components/Word.vue";
import Sidebar from "@/components/Sidebar.vue";
import Header from "@/components/Header.vue";
import http from 'http'

export default {
  components: {
    Word,
    Sidebar,
    Header,

  },
  data: () => ({
    data: null,
    links: ["Dashboard", "Messages", "Profile", "Updates"],
    info: null,
    // word: {
    //   word: "hello",
    //   img:
    //     "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Young_girl_smiling_in_sunshine_%282%29.jpg/1200px-Young_girl_smiling_in_sunshine_%282%29.jpg",
    //   meaning: "working",
    //   source: "en",
    //   target: "de",
    //   sourceLang: "English",
    //   targetLang: "German",
    //   hearts: 3,
    //   copyLinks: 53,
    // },
    word: {
      word: "done",
      meaning: "jump",
      sourceLang: "tl",
      targetLang: "de",
      translation: "arbeit",
      likes: "0",
      shares: "0",
      _mby: "61192c00fbf06b14617f9991",
      _by: "61192c00fbf06b14617f9991",
      _modified: 1630158317,
      _created: 1630151244,
      _id: "612a224c01a9e605e832d861",
      img: null,
    },
  }),
  mounted: function() {
    // console.log(process.env.VUE_APP_UNSPLASH_API);

    this.word.word = this.$route.params.word;
    this.word.source = this.$route.params.source;
    // this.word.target = this.$route.params.target;

    // this.getWord();
   //  this.getWordOX();
    //this.test()
 //  this.test2()
  this.test3()
  },
  methods: {

    test3(){
      const app_id = "6143d1e6"; // insert your APP Id
const app_key = "165be569715ae785c0a89594f8fc7d29"; // insert your APP Key
const wordId = "ace";
const fields = "pronunciations";
const strictMatch = "false";

const options = {
  host: 'od-api.oxforddictionaries.com',
  port: '443',
  path: '/api/v2/entries/en-gb/' + wordId + '?fields=' + fields + '&strictMatch=' + strictMatch,
  method: "GET",
  headers: {
    'app_id': app_id,
    'app_key': app_key
  }
};

http.get(options, (resp) => {
    let body = '';
    resp.on('data', (d) => {
        body += d;
    });
    resp.on('end', () => {
        let parsed = JSON.stringify(body);

        console.log(parsed);
    });
});
    },


    test2(){

      let myUrl = "https://www.carqueryapi.com/api/0.3/?callback=?&cmd=getMakes"
      const myHeaders = {
        'X-Auth-Token': "token",
        'content-type': 'application/json',
         "Access-Control-Allow-Origin": "*",
          mode: 'no-cors', // no-cors, *cors, same-origin
    };
// console.log(myHeaders);
const request = axios.create({
    myUrl,
    headers: myHeaders,
    withCredentials: true
})


request.get() // If you add a string inside the brackets it gets appended to your url
    .then(res => console.log(res))
    .catch(err => console.log(err))


      

    },

   async test(){

let url = "https://od-api.oxforddictionaries.com/api/v2/entries/en-gb/ace?strictMatch=false"

// Example POST method implementation:
async function postData(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'GET', 
    credentials: 'include',
     host: 'od-api.oxforddictionaries.com',
  port: '443',
  path: '/api/v2/entries/en-gb/' + "work" + '?&strictMatch=false' ,
  method: "GET",
    // method: 'GET', // *GET, POST, PUT, DELETE, etc.
    // mode: 'cors', // no-cors, *cors, same-origin
    // "Access-Control-Allow-Origin": "http://127.0.0.1",
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    // "app_id": "6143d1e6",
    //  "app_key": "165be569715ae785c0a89594f8fc7d29",
    //   "Accept": "application/json",
    headers: {
      // 'Content-Type': 'text/plain',
          //  "Accept": "application/json",
          //  "Access-Control-Allow-Origin": "http://127.0.0.1",
        //  'origin': 'http://localhost:8081',
         "app_id": "6143d1e6",
     "app_key": "165be569715ae785c0a89594f8fc7d29",
      // "Accept": "application/json",
            
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    // body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  // console.log(response)
  return response.json(); // parses JSON response into native JavaScript objects
}

postData(url, { answer: 42 })
  .then(data => {
    console.log(data); // JSON data parsed by `data.json()` call
  });





    },
    getWord() {
      // console.log(this.$route.params.word);
      axios
        .get(
          "http://localhost:8080/api/collections/get/words?filter[word]=" + this.$route.params.word
        )
        .then((response) => {
          // handle success
          this.word = response.data.entries[0];
          console.log(response.data.entries[0]);
          if (response.data.entries[0].oxford === null) {
            console.log("empty");
          }
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        })
        .then(function() {
          // always executed
        });
    },

  async  getWordOX() {
      // console.log(process.env.VUE_APP_OXFORD_BASE_URL);

       const endPoint = 'https://od-api.oxforddictionaries.com/api/v2/entries/en-gb/ace?strictMatch=false'
       
    
    const headers = {
      headers: {
              'Authorization': 'Bearer ',
              "Accept": "application/json",
              "app_id": "6143d1e6",
              "app_key": "165be569715ae785c0a89594f8fc7d29",
              "test": "test",
               "Access-Control-Allow-Origin": "http://localhost:8081",
                 "mode": 'same-origin', // no-cors, *cors, same-origin
      }
    }
    
    const requestBody = {
      someField: "postBody.someField",
      anotherField: "postBody.anotherField"
    }


     await axios
        .get(endPoint,  requestBody, headers      )
        .then((response) => {
          // handle success
          this.word = response.data.entries[0];
          console.log(response.data.entries[0]);
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        })
        .then(function() {
          // always executed
        });
    },
  },
};
</script>
