<template>
    
     <v-card
    class="mx-auto"
    color="#26c6da"
    dark
   
  >
    <v-card-title>
      <!-- <v-icon
        large
        left
      >
        mdi-twitter
      </v-icon> -->
      <span class="text-h6 font-weight-light">{{word.word}}</span>
    </v-card-title>

    <v-img class="pic" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Young_girl_smiling_in_sunshine_%282%29.jpg/1200px-Young_girl_smiling_in_sunshine_%282%29.jpg"></v-img>


    <!-- <v-card-text class="text-h5 font-weight-bold">
      "Turns out semicolon-less style is easier and safer in TS because most gotcha edge cases are type invalid as well."
    </v-card-text> -->

    <v-card-actions>
      <v-list-item class="grow">
        <!-- <v-list-item-avatar color="grey darken-3">
          <v-img
            class="elevation-6"
            alt=""
            src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
          ></v-img>
        </v-list-item-avatar> -->

        <v-list-item-content>
          <v-list-item-title>{{word.meaning}}</v-list-item-title>
        </v-list-item-content>

        <!-- <v-row
          align="center"
          justify="end"
        >
          <v-icon class="mr-1">
            mdi-heart
          </v-icon>
          <span class="subheading mr-2">256</span>
          <span class="mr-1">·</span>
          <v-icon class="mr-1">
            mdi-share-variant
          </v-icon>
          <span class="subheading">45</span>
        </v-row> -->
      </v-list-item>
    </v-card-actions>
  </v-card>


</template>



<script>


export default {
    
  components:{
  

  },
  props: ["word"],
  data: () => ({
 
  }),
  mounted: function() {
   
  },
};
</script>




<style scoped>

.pic{
  max-height: 40vh;
}


</style>

