<template>
  <!-- <v-app id="inspire"> -->
  <!-- <v-app-bar
      app
      shrink-on-scroll
    >
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title>Application</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar> -->

  <v-main>
    <v-container>
      <v-row>
        <v-col v-for="(word, i) in words" :key="i" cols="4">
          <!-- <v-card height="200"></v-card>/= -->
          <WordPreview v-bind:word="word"></WordPreview>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
  <!-- </v-app> -->
</template>

<script>
import HelloWorld from "../components/HelloWorld";
import LanguageSelect from "../components/LanguageSelect";
import WordPreview from "../components/WordPreview";

export default {
  name: "Home",

  components: {
    HelloWorld,
    LanguageSelect,
    WordPreview,
  },
  data: () => ({
    words: [
      {
        word: "hello",
        img:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Young_girl_smiling_in_sunshine_%282%29.jpg/1200px-Young_girl_smiling_in_sunshine_%282%29.jpg",
          meaning: "working"
      
      },
      {
        word: "hello",
        img:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Young_girl_smiling_in_sunshine_%282%29.jpg/1200px-Young_girl_smiling_in_sunshine_%282%29.jpg",
      meaning: "working"
      },
          {
        word: "hello",
        img:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Young_girl_smiling_in_sunshine_%282%29.jpg/1200px-Young_girl_smiling_in_sunshine_%282%29.jpg",
      meaning: "working"
      },
          {
        word: "hello",
        img:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Young_girl_smiling_in_sunshine_%282%29.jpg/1200px-Young_girl_smiling_in_sunshine_%282%29.jpg",
     meaning: "working"
     },
          {
        word: "hello",
        img:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Young_girl_smiling_in_sunshine_%282%29.jpg/1200px-Young_girl_smiling_in_sunshine_%282%29.jpg",
      meaning: "working"
      },
        {
        word: "hello",
        img:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Young_girl_smiling_in_sunshine_%282%29.jpg/1200px-Young_girl_smiling_in_sunshine_%282%29.jpg",
      meaning: "working"
      },
    ],
  }),
};
</script>
