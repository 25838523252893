<template>
    
     <v-card
    class="mx-auto"
    color="#26c6da"
    dark
   
  >
    <v-card-title>
      <!-- <v-icon
        large
        left
      >
        mdi-twitter
      </v-icon> -->
      <span class="text-h6 font-weight-light">{{word.word}}</span>
    </v-card-title>

    <v-img class="pic" v-bind:src="word.img"></v-img>


    <v-card-text class="text-h5 font-weight-bold">
      "{{word.meaning}}"
    </v-card-text>

    <v-card-actions>
      <v-list-item class="grow">
        <!-- <v-list-item-avatar color="grey darken-3">
          <v-img
            class="elevation-6"
            alt=""
            src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
          ></v-img>
        </v-list-item-avatar> -->

        <v-list-item-content>
          <v-list-item-title>{{word.sourceLang}}</v-list-item-title>
        </v-list-item-content>

        <v-row
          align="center"
          justify="end"
        >

          <!-- <v-icon class="mr-1">
            mdi-heart
          </v-icon>
          <span class="subheading mr-2">{{word.hearts}}</span> -->

          <CounterButton v-bind:data="likeIconData" ></CounterButton>
            <span class="subheading mr-2"></span>

          <span class="mr-1">·</span>


            <CounterButton v-bind:data="shareIconData" ></CounterButton>


        </v-row>
      </v-list-item>
    </v-card-actions>
  </v-card>


</template>



<script>
import CounterButton from "@/components/CounterButton.vue";

export default {
    
  components:{
  CounterButton,


  },
  props: ["word"],
  data: () => ({
      link_url: "http://localhost:8081/en/de/cat",
        link_name: "cat",
        pageLink: "http://localhost:8081/en/de/cat",
        copyCounts: 34,
        show: false,

        likeIconData:{
             icon: "mdi-heart",
             count: 29,
             tooltipText: "Favorited!",

        },

             shareIconData:{
             icon: "mdi-share-variant",
             count:16,
             tooltipText: "Link copied!",

        },



  }),
  mounted: function() {
      this.pageLink = window.location.href
   
  },
  
   methods: {


    copyPageLink () {
        //update link
        this.pageLink = window.location.href

          let testingCodeToCopy = document.querySelector('#testing-code')
          testingCodeToCopy.setAttribute('type', 'text')    // 不是 hidden 才能複製
          testingCodeToCopy.select()

          try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            // alert('Testing code was copied ' + msg);
          } catch (err) {
            // alert('Oops, unable to copy');
          }

          /* unselect the range */
          testingCodeToCopy.setAttribute('type', 'hidden')
          window.getSelection().removeAllRanges()
        },



    },

     

};
</script>




<style scoped>

.pic{
  max-height: 40vh;
}


</style>

