<template>
  <div>
    <v-icon class="mr-1" v-on:click.stop.prevent="copyPageLink" v-on:click="show = !show">
      {{ data.icon }}
    </v-icon>

    <span class="subheading">{{ data.count }}</span>

    <v-tooltip v-model="show" top>
      <template v-slot:activator="{ attrs }">
        <span v-bind="attrs"></span>

        <input type="hidden" id="testing-code" :value="data.pageLink" />
      </template>
      <span>{{data.tooltipText}}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: ["data"],
  components: {},
  data: () => ({
    show: false,
    pageLink: "http://localhost:8081/en/de/cat",
  }),
  mounted: function() {},

  methods: {
    copyPageLink() {
      //update link
      this.pageLink = window.location.href;

      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        // alert('Testing code was copied ' + msg);
      } catch (err) {
        // alert('Oops, unable to copy');
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>
