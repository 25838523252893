<template>
  <div>
   <Header></Header>

    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
        
        <Sidebar></Sidebar>

          <v-col cols="10">
            <!-- <v-sheet min-height="70vh" rounded="lg"> -->
              <!--  -->
 
            

            <Word v-bind:word="word" ></Word>


            <!-- </v-sheet> -->
          </v-col>
        </v-row>
      </v-container>
    </v-main>
</div>
</template>

<script>
import axios from "axios";
import Word from "@/components/Word.vue";
import Header from "@/components/Header.vue";
import Sidebar from "@/components/Sidebar.vue";

export default {
  components:{
    Word,
    Header,
Sidebar,


  },
  data: () => ({
    links: ["Dashboard", "Messages", "Profile", "Updates"],
    info: null,
    word: {
      word: "hello",
      img:"https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Young_girl_smiling_in_sunshine_%282%29.jpg/1200px-Young_girl_smiling_in_sunshine_%282%29.jpg",
      meaning: "working",
      source: "en",
      target: "de",
      sourceLang: "English",
      targetLang: "German",
      hearts: 3,
      copyLinks: 53,

    }
  }),
  mounted: function() {
    console.log("boo");
    console.log(process.env.VUE_APP_UNSPLASH_API);
    axios
      .get("https://api.coindesk.com/v1/bpi/currentprice.json")
      .then((response) => (this.info = response));

    console.log(this.info);

    // this.word.img = "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Young_girl_smiling_in_sunshine_%282%29.jpg/1200px-Young_girl_smiling_in_sunshine_%282%29.jpg"
    console.log( this.$route.params.source )

    this.word.word = this.$route.params.word
    this.word.source = this.$route.params.source
    this.word.target = this.$route.params.target


  },
};
</script>


