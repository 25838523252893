import Vue from 'vue'
import VueRouter from 'vue-router'


//views
import HomeView from '../views/HomeView.vue'
import QuizView from '../views/QuizView.vue'
import MeaningView from '../views/MeaningView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/quiz/:source/:target/:word',
    name: 'QuizView',
    component: QuizView
  },
  {
    path: '/meaning/:source/:word',
    name: 'MeaningView',
    component: MeaningView
  },
  {
    path: '/about',
    name: 'AboutView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
